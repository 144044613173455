
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.common-data {
  width: 100%;
  background-color: $color-white;
  padding: 20px 16px;
  border-radius: $base-border-radius;
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & + & {
      margin-top: 16px;
    }
  }

  &__cell {
    width: calc(50% - 10px);
    &:first-child:last-child {
      width: 100%;
    }
  }
}
