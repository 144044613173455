
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

::v-deep .esmp-select-item {
  display: flex;
  flex-wrap: wrap;
}
.user-name {
  margin-right: 6px;
}
.user-position {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-primary-1-day;
  white-space: normal;
}
.user-email {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-black-op-40;
}
.user-fired {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-red;
}
